<template>
  <form :id="id" class="row g-3" @submit.prevent="formSubmit">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-auto">
          <div class="text-center mb-1">
            <span
              class="fa fa-exclamation-triangle fa-10x text-warning mb-3"
              aria-hidden="true"
            ></span>
          </div>
          <div class="text-center mb-3">
            <span class="text-break fw-bolder lh-sm text-warning">
              ¿ESTAS SEGURO DE
              <span :class="statusUser.color">{{ statusUser.text }}</span> A
              EL(LA) SOCIO?
            </span>
          </div>
        </div>
        <div class="col-md-auto">
          <div v-show="!loading">
            <div class="d-grid gap-2 d-md-block">
              <button type="submit" class="btn btn-success me-md-2">
                <span class="fa fa-check" aria-hidden="true"></span> Si
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                @click="close"
                ref="closeModal"
              >
                <span class="fa fa-times" aria-hidden="true"></span> No
              </button>
            </div>
          </div>
          <div v-show="loading">
            <div class="d-grid gap-1 d-md-block">
              <button class="btn btn-success disabled">
                <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
                Cargando
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { errorRequest, successNotify } from '@helpers'

export default {
  name: 'FormStatusDelete',
  props: {
    id: {
      type: String,
    },
    status: {
      type: Boolean,
    },
    close: {
      type: Function,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      user: {},
      loading: false,
    }
  },
  computed: {
    statusUser() {
      let user = this.user
      let color = 'text-success'
      let text = 'ACTIVAR'

      if (user.status) {
        color = 'text-danger'
        text = 'INACTIVAR'
      }

      if (!this.status) {
        color = 'text-primary'
        text = 'ELIMINAR'
      }

      return {
        text,
        color,
      }
    },
  },
  created() {
    this.user = { ...this.data }
  },
  methods: {
    ...mapActions({
      statusAction: 'user/updateStatus',
      deleteAction: 'user/delete',
    }),
    async formSubmit() {
      if (this.status) return await this.statusForm()
      return await this.deleteForm()
    },
    async statusForm() {
      this.loading = true
      await this.statusAction(this.user).then(
        async (response) => {
          this.loading = false
          successNotify(
            {
              message: 'Se cambio el estado con exito',
            },
            this.$swal
          )
          await this.$refs.closeModal.click()
          return await this.$parent.$parent.$parent.loadUsers()
        },
        (error) => {
          this.loading = false
          return errorRequest(error, this.$swal)
        }
      )
      this.loading = false
    },
    async deleteForm() {
      this.loading = true
      await this.deleteAction(this.user).then(
        async (response) => {
          this.loading = false
          successNotify(
            {
              message: 'Eliminado con exito',
            },
            this.$swal
          )
          await this.$refs.closeModal.click()
          return await this.$parent.$parent.$parent.loadUsers()
        },
        (error) => {
          this.loading = false
          return errorRequest(error, this.$swal)
        }
      )
      this.loading = false
    },
  },
}
</script>
