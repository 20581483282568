<template>
  <fragment>
    <div class="table-settings mb-2" :class="initializeUsers ? 'disabled' : ''">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-auto d-grid gap-2 d-md-block text-left mb-4">
          <button
            type="button"
            class="btn btn-custom2 me-md-2"
            @click="openCreateModal"
          >
            <span class="fa fa-user" aria-hidden="true"></span> Nuevo Socio
          </button>
          <button
            type="button"
            class="btn btn-success me-md-2"
            @click="openImportModal"
          >
            <span class="fa fa-file-excel" aria-hidden="true"></span>
            Importación
          </button>
          <button
            type="button"
            class="btn btn-danger me-md-2"
            @click="openReportModal"
          >
            <span class="fa fa-file-pdf" aria-hidden="true"></span> Reporte
          </button>
        </div>
        <div class="col-md-auto mb-4">
          <div class="input-group">
            <input
              type="text"
              class="form-control pl-2"
              id="exampleInputIconLeft"
              placeholder="Buscar"
              v-model="searchUser"
            />
            <button class="btn btn-info" @click="fetchUsers">
              <span class="fas fa-search" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-light shadow-sm table-wrapper pt-0">
      <div class="card-body table-responsive">
        <div class="overlay" :class="initializeUsers ? 'overlay-show' : ''">
          <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="spinner"></div>
          </div>
        </div>
        <table class="table table-hover">
          <thead class="text-white">
            <tr class="border-top-0 bg-custom">
              <th class="border-gray-200">#</th>
              <th class="border-gray-200">Nombres</th>
              <th class="border-gray-200">Apellidos</th>
              <th class="border-gray-200">Teléfono</th>
              <th class="border-gray-200">Correo</th>
              <th class="border-gray-200">Usuario</th>
              <th class="border-gray-200 text-center">Estado</th>
              <th class="border-gray-200">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <template>
              <template v-if="dataTable.length === 0 && !initDataPagination">
                <tr>
                  <td class="text-center" colspan="8"
                    >No hay registros de socios</td
                  >
                </tr>
              </template>
              <template v-else>
                <template v-for="(item, index) in dataTable">
                  <tr class="border-bottom border-primary-opacity" :key="index">
                    <td v-html="pagination.from + index"></td>
                    <td
                      ><span
                        class="font-weight-normal"
                        v-html="item.first_name"
                      ></span
                    ></td>
                    <td
                      ><span
                        class="font-weight-normal"
                        v-html="item.first_last_name"
                      ></span
                    ></td>
                    <td
                      ><span
                        class="font-weight-normal"
                        v-html="item.telephone"
                      ></span
                    ></td>
                    <td
                      ><span
                        class="font-weight-normal"
                        v-html="item.email"
                      ></span
                    ></td>
                    <td
                      ><span
                        class="font-weight-normal"
                        v-html="item.username"
                      ></span
                    ></td>
                    <td class="text-center">
                      <span
                        class="font-weight-normal"
                        v-html="statusUser[index]"
                      ></span>
                    </td>
                    <td>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          @click.prevent="openUpdateModal(item)"
                        >
                          <span class="fa fa-edit" aria-hidden="true"></span>
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click.prevent="openActiveModal(item)"
                        >
                          <span class="fa fa-sync" aria-hidden="true"></span>
                        </button>
                        <button
                          type="button"
                          class="btn btn-custom2 btn-sm"
                          @click.prevent="openEmailModal(item)"
                        >
                          <span
                            class="fa fa-envelope"
                            aria-hidden="true"
                          ></span>
                        </button>
                        <button
                          type="button"
                          class="btn btn-dark btn-sm"
                          @click.prevent="openDeleteModal(item)"
                        >
                          <span class="fa fa-trash" aria-hidden="true"></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div class="card-footer border-0">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-auto text-center mb-2">
            <div class="font-weight-bold small">
              Mostrando
              <b>{{ pagination.from }}</b> a <b>{{ pagination.to }}</b> de
              <b>{{ pagination.total }}</b>
              entradas
            </div>
          </div>
          <div class="col-md-auto mb-2" v-if="pagination.last_page > 1">
            <pagination
              :class="initializeUsers ? 'disabled' : ''"
              :pagination.sync="pagination"
              :offset="10"
              @paginate="loadUsers(true)"
            />
          </div>
        </div>
      </div>
      <create-record
        :id="modalID"
        :close="closeCreateModal"
        v-if="modalCreate"
      />
      <update-record
        :id="`${modalID}-update`"
        :close="closeUpdateModal"
        v-if="modalUpdate"
      />
      <status-record
        :id="`${modalID}-active`"
        :close="closeActiveModal"
        v-if="modalActive"
      />
      <delete-record
        :id="`${modalID}-delete`"
        :close="closeDeleteModal"
        v-if="modalDelete"
      />
      <report-action
        :id="`${modalID}-report`"
        :close="closeReportModal"
        v-if="modalReport"
      />
      <import-action
        :id="`${modalID}-import`"
        :close="closeImportModal"
        v-if="modalImport"
      />
      <email-action
        :id="`${modalID}-email`"
        :close="closeEmailModal"
        v-if="modalEmail"
      />
    </div>
  </fragment>
</template>

<script>
import Pagination from '@components/app/Pagination.vue'
import CreateRecord from '@views/user/actions/crud/CreateRecord.vue'
import UpdateRecord from '@views/user/actions/crud/UpdateRecord.vue'
import StatusRecord from '@views/user/actions/crud/StatusRecord.vue'
import DeleteRecord from '@views/user/actions/crud/DeleteRecord.vue'
import ReportAction from '@views/user/actions/ReportAction.vue'
import ImportAction from '@views/user/actions/ImportAction.vue'
import EmailAction from '@views/user/actions/EmailAction.vue'
import { mapActions } from 'vuex'
import { errorRequest } from '@helpers'
import { Modal } from 'bootstrap'

export default {
  name: 'ListRecords',
  components: {
    Pagination,
    CreateRecord,
    UpdateRecord,
    StatusRecord,
    DeleteRecord,
    ReportAction,
    ImportAction,
    EmailAction,
  },
  data: function () {
    return {
      dataTable: [],
      pagination: {
        current_page: 1,
      },
      initializeUsers: true,
      initializeLoadPaginate: true,
      initDataPagination: false,
      searchUser: '',
      modalID: 'users',
      modalCreate: false,
      modalUpdate: false,
      modalActive: false,
      modalDelete: false,
      modalReport: false,
      modalImport: false,
      modalEmail: false,
    }
  },
  computed: {
    statusUser() {
      if (this.dataTable.length) {
        return this.dataTable.map(function (item) {
          let status = item.status
          let icon = 'fa-check'
          let color = 'text-success'
          if (!status) {
            icon = 'fa-times'
            color = 'text-danger'
          }

          return `<span class="fa ${icon} ${color}" aria-hidden="true"></span>`
        })
      }

      return '-'
    },
  },
  async mounted() {
    //await this.$parent.validateRoleMenu(this.$route.name)
    this.setBreadCrumb('Socios')
    await this.fetchUsers()
  },
  async updated() {
    await this.$parent.validateRoleMenu(this.$route.name)
  },
  methods: {
    ...mapActions({
      setBreadCrumb: 'setBreadCrumb',
      loadDataTable: 'user/loadDataTable',
      setUser: 'user/setDataUpdate',
      unSetUser: 'user/unSetDataUpdate',
    }),
    async fetchUsers() {
      this.initializeUsers = true
      this.pagination = { current_page: 1 }
      this.loadDataTable({
        page: this.pagination.current_page,
        q: this.searchUser,
      }).then(
        (response) => {
          this.dataTable = response.data
          this.pagination = response.pagination
          this.initializeUsers = false
        },
        (error) => {
          this.initializeUsers = false
          return errorRequest(error, this.$swal)
        }
      )
    },
    async loadUsers(loader = false) {
      if (loader) this.initializeUsers = true
      await this.loadDataTable({
        page: this.pagination.current_page,
        q: this.searchUser,
      }).then(
        (response) => {
          this.dataTable = response.data
          this.pagination = response.pagination
          if (loader) this.initializeUsers = false
        },
        (error) => {
          this.initializeUsers = false
          return errorRequest(error, this.$swal)
        }
      )
    },
    openCreateModal() {
      this.modalCreate = true
      this.$nextTick(() => {
        let createModal = new Modal(document.getElementById(this.modalID), {})
        return createModal.show()
      })
    },
    closeCreateModal() {
      setTimeout(() => (this.modalCreate = false), 100)
    },
    async openUpdateModal(data) {
      this.modalUpdate = true
      this.$nextTick(async () => {
        await this.setUser(data)
        this.$nextTick(() => {
          let updateModal = new Modal(
            document.getElementById(`${this.modalID}-update`),
            {}
          )
          return updateModal.show()
        })
      })
    },
    async closeUpdateModal() {
      await this.unSetUser()
      setTimeout(() => (this.modalUpdate = false), 100)
    },
    async openActiveModal(data) {
      this.modalActive = true
      this.$nextTick(async () => {
        await this.setUser(data)
        this.$nextTick(() => {
          let activeModal = new Modal(
            document.getElementById(`${this.modalID}-active`),
            {}
          )
          return activeModal.show()
        })
      })
    },
    async closeActiveModal() {
      await this.unSetUser()
      setTimeout(() => (this.modalActive = false), 100)
    },
    async openDeleteModal(data) {
      this.modalDelete = true
      this.$nextTick(async () => {
        await this.setUser(data)
        this.$nextTick(() => {
          let deleteModal = new Modal(
            document.getElementById(`${this.modalID}-delete`),
            {}
          )
          return deleteModal.show()
        })
      })
    },
    async closeDeleteModal() {
      await this.unSetUser()
      setTimeout(() => (this.modalDelete = false), 100)
    },
    openReportModal() {
      this.modalReport = true
      this.$nextTick(() => {
        let reportModal = new Modal(
          document.getElementById(`${this.modalID}-report`),
          {}
        )
        return reportModal.show()
      })
    },
    closeReportModal() {
      setTimeout(() => (this.modalReport = false), 100)
    },
    openImportModal() {
      this.modalImport = true
      this.$nextTick(() => {
        let importModal = new Modal(
          document.getElementById(`${this.modalID}-import`),
          {}
        )
        return importModal.show()
      })
    },
    closeImportModal() {
      setTimeout(() => (this.modalImport = false), 100)
    },
    async openEmailModal(data) {
      this.modalEmail = true
      this.$nextTick(async () => {
        await this.setUser(data)
        this.$nextTick(() => {
          let emailModal = new Modal(
            document.getElementById(`${this.modalID}-email`),
            {}
          )
          return emailModal.show()
        })
      })
    },
    async closeEmailModal() {
      await this.unSetUser()
      setTimeout(() => (this.modalEmail = false), 100)
    },
  },
}
</script>
