<template>
  <modal :id="id" :size="modalSize">
    <template v-slot:header>
      <h2 class="h5 modal-title"
        ><span class="fa fa-user-tie" aria-hidden="true"></span> Nuevo Socio</h2
      >
      <a data-bs-dismiss="modal" aria-label="Close" @click="close">
        <span class="fa fa-times" aria-hidden="true"></span>
      </a>
    </template>
    <template v-slot:body>
      <form-create-update
        :id="id"
        :update="false"
        :close="close"
        :icon="'fa fa-save'"
        :btntext="'Guardar'"
        :btnclass="'btn-custom2'"
        :data="user"
      />
    </template>
  </modal>
</template>

<script>
import Modal from '@components/app/Modal.vue'
import FormCreateUpdate from '@components/user/FormCreateUpdate.vue'

export default {
  name: 'CreateRecord',
  props: ['id', 'close'],
  components: {
    Modal,
    FormCreateUpdate,
  },
  data: function () {
    return {
      modalSize: 'xl',
      user: {
        first_name: '',
        second_name: '',
        first_last_name: '',
        second_last_name: '',
        type_document_id: '',
        nro_document: '',
        date_born: '',
        gender: '',
        marital_status_id: '',
        born_place: '',
        country_id: '',
        residence_id: '',
        housing_type_id: '',
        working_condition_id: '',
        job_title_id: '',
        grade_instruction_id: '',
        profession_id: '',
        economic_activity_id: '',

        address: '',
        workplace: '',
        email: '',
        telephone: '',
        observations: '',

        password: '',
        re_password: '',
      },
    }
  },
}
</script>
