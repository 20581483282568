<template>
  <form :id="id" class="row g-3" @submit.prevent="formSubmit">
    <span class="half-title h5 border-light">Datos personales</span>
    <div class="col-md-3">
      <label class="form-label">Apellido Paterno</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.first_last_name.$error }"
        placeholder="Ape. Paterno"
        v-model.trim="$v.user.first_last_name.$model"
      />
    </div>
    <div class="col-md-3">
      <label class="form-label">Apellido Materno</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.second_last_name.$error }"
        placeholder="Ape. Materno"
        v-model.trim="$v.user.second_last_name.$model"
      />
    </div>
    <div class="col-md-3">
      <label class="form-label">Primer Nombre</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.first_name.$error }"
        placeholder="Primer Nombre"
        v-model.trim="$v.user.first_name.$model"
      />
    </div>
    <div class="col-md-3">
      <label class="form-label">Segundo Nombre</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.second_name.$error }"
        placeholder="Segundo Nombre"
        v-model.trim="$v.user.second_name.$model"
      />
    </div>

    <div class="col-md-3">
      <label class="form-label">Tipo Documento</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.type_document_id.$error }"
        v-model="$v.user.type_document_id.$model"
        :options="type_documents.map((type) => type.id)"
        :custom-label="(opt) => type_documents.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-3">
      <label class="form-label">Nro Documento</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.nro_document.$error }"
        placeholder="Nro Documento"
        v-model.trim="$v.user.nro_document.$model"
      />
    </div>
    <div class="col-md-3">
      <label class="form-label">Género</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.gender.$error }"
        v-model="$v.user.gender.$model"
        :options="genders.map((type) => type.id)"
        :custom-label="(opt) => genders.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-3">
      <label class="form-label">Estado Civil</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.marital_status_id.$error }"
        v-model="$v.user.marital_status_id.$model"
        :options="marital_status.map((type) => type.id)"
        :custom-label="(opt) => marital_status.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>

    <div class="col-md-4">
      <label class="form-label">Fecha Nacimiento</label>
      <input
        type="date"
        class="form-control"
        :class="{ 'is-invalid': $v.user.date_born.$error }"
        placeholder="Fecha Nacimiento"
        v-model.trim="$v.user.date_born.$model"
        :max="date_now"
      />
    </div>
    <div class="col-md-4">
      <label class="form-label">Edad</label>
      <input type="text" class="form-control" v-model="age" disabled />
    </div>
    <div class="col-md-4">
      <label class="form-label">Nacionalidad</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.country_id.$error }"
        v-model="$v.user.country_id.$model"
        :options="countries.map((type) => type.id)"
        :custom-label="(opt) => countries.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>

    <span class="half-title h5 border-light">Ubicación</span>
    <div class="col-md-6">
      <label class="form-label">Residencia</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.residence_id.$error }"
        v-model="$v.user.residence_id.$model"
        :options="countries.map((type) => type.id)"
        :custom-label="(opt) => countries.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-6">
      <label class="form-label">Tipo Vivienda</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.housing_type_id.$error }"
        v-model="$v.user.housing_type_id.$model"
        :options="housing_types.map((type) => type.id)"
        :custom-label="(opt) => housing_types.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-6">
      <label class="form-label">Dirección</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.address.$error }"
        placeholder="Dirección"
        v-model.trim="$v.user.address.$model"
      />
    </div>
    <div class="col-md-6">
      <label class="form-label">Lugar Nacimiento</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.born_place.$error }"
        placeholder="Lugar Nacimiento"
        v-model.trim="$v.user.born_place.$model"
      />
    </div>

    <span class="half-title h5 border-light">Datos Laborales</span>
    <div class="col-md-4">
      <label class="form-label">Condición Laboral</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.working_condition_id.$error }"
        v-model="$v.user.working_condition_id.$model"
        :options="working_conditions.map((type) => type.id)"
        :custom-label="
          (opt) => working_conditions.find((x) => x.id == opt).name
        "
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-4">
      <label class="form-label">Ocupación</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.job_title_id.$error }"
        v-model="$v.user.job_title_id.$model"
        :options="job_titles.map((type) => type.id)"
        :custom-label="(opt) => job_titles.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-4">
      <label class="form-label">Grado Instrucción</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.grade_instruction_id.$error }"
        v-model="$v.user.grade_instruction_id.$model"
        :options="grade_instructions.map((type) => type.id)"
        :custom-label="
          (opt) => grade_instructions.find((x) => x.id == opt).name
        "
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-4">
      <label class="form-label">Profesión</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.profession_id.$error }"
        v-model="$v.user.profession_id.$model"
        :options="professions.map((type) => type.id)"
        :custom-label="(opt) => professions.find((x) => x.id == opt).name"
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-4">
      <label class="form-label">Actividad Economica</label>
      <multiselect
        :class="{ 'is-invalid': $v.user.economic_activity_id.$error }"
        v-model="$v.user.economic_activity_id.$model"
        :options="economic_activities.map((type) => type.id)"
        :custom-label="
          (opt) => economic_activities.find((x) => x.id == opt).name
        "
        placeholder="Seleccione"
        :allow-empty="false"
      ></multiselect>
    </div>
    <div class="col-md-4">
      <label class="form-label">Centro Laboral</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.user.workplace.$error }"
        placeholder="Centro Laboral"
        v-model.trim="$v.user.workplace.$model"
      />
    </div>

    <span class="half-title h5 border-light">Datos Contacto</span>
    <div class="col-md-6">
      <label class="form-label">Email</label>
      <input
        type="email"
        class="form-control"
        :class="{ 'is-invalid': $v.user.email.$error }"
        placeholder="Email"
        v-model.trim="$v.user.email.$model"
      />
    </div>
    <div class="col-md-6">
      <label class="form-label">Teléfono</label>
      <input
        type="number"
        class="form-control"
        :class="{ 'is-invalid': $v.user.telephone.$error }"
        placeholder="Teléfono"
        v-model.trim="$v.user.telephone.$model"
      />
    </div>

    <span class="half-title h5 border-light">Otros</span>
    <div class="col-md-12">
      <label class="form-label">Observaciones</label>
      <textarea
        class="form-control"
        :class="{ 'is-invalid': $v.user.observations.$error }"
        placeholder="Observaciones"
        v-model.trim="$v.user.observations.$model"
      />
    </div>

    <span class="half-title h5 border-light">Credenciales</span>
    <div class="col-md-6">
      <label class="form-label">Contraseña</label>
      <input
        type="password"
        class="form-control"
        :class="{ 'is-invalid': $v.user.password.$error }"
        :placeholder="update ? '********' : 'Contraseña'"
        v-model.trim="$v.user.password.$model"
      />
    </div>
    <div class="col-md-6">
      <label class="form-label">Repetir Contraseña</label>
      <input
        type="password"
        class="form-control"
        :class="{ 'is-invalid': $v.user.re_password.$error }"
        :placeholder="update ? '********' : 'Repetir Contraseña'"
        v-model.trim="$v.user.re_password.$model"
      />
    </div>
    <div class="mt-4 modal-footer border-top border-5 border-light">
      <div v-show="!loading">
        <button
          type="button"
          class="btn btn-danger mr-2"
          data-bs-dismiss="modal"
          @click="close"
          ref="closeModal"
        >
          <span class="fa fa-times" aria-hidden="true"></span> Cancelar
        </button>
        <button type="submit" class="btn" :class="btnclass">
          <span :class="icon" aria-hidden="true"></span> {{ btntext }}
        </button>
      </div>
      <div v-show="loading">
        <button class="btn disabled" :class="btnclass">
          <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
          Cargando
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'
import { errorRequest, successNotify } from '@helpers'
import moment from 'moment'

export default {
  name: 'FormCreateUpdate',
  props: {
    update: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    close: {
      type: Function,
    },
    icon: {
      type: String,
    },
    btntext: {
      type: String,
    },
    btnclass: {
      type: String,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      user: {},
      loading: false,
      date_now: moment().format('YYYY-MM-DD'),
      age: 'Se calcula por la F. Nac.',
    }
  },
  computed: {
    ...mapState({
      type_documents: (state) => state.helper.typeDocumentData,
      genders: (state) => state.helper.genderData,
      marital_status: (state) => state.helper.maritalData,
      countries: (state) => state.helper.countryData,
      housing_types: (state) => state.helper.housingTypeData,
      working_conditions: (state) => state.helper.workingConditionData,
      job_titles: (state) => state.helper.jobTitleData,
      grade_instructions: (state) => state.helper.gradeInstructionData,
      professions: (state) => state.helper.professionData,
      economic_activities: (state) => state.helper.economicActivityData,
    }),
  },
  validations() {
    let validate = {
      first_name: { required },
      second_name: {},
      first_last_name: { required },
      second_last_name: { required },
      type_document_id: { required },
      nro_document: { required },
      date_born: { required },
      gender: { required },
      marital_status_id: { required },
      born_place: { required },
      country_id: { required },
      residence_id: { required },
      housing_type_id: { required },
      working_condition_id: { required },
      job_title_id: { required },
      grade_instruction_id: { required },
      profession_id: { required },
      economic_activity_id: { required },

      address: { required },
      workplace: { required },
      email: { required },
      telephone: { required },
      observations: { required },
      password: {},
      re_password: {},
    }

    if (!this.update) {
      validate.password = { required }
      validate.re_password = { required, sameAsPassword: sameAs('password') }
    }

    return {
      user: validate,
    }
  },
  watch: {
    'user.date_born': function (newVal, oldVal) {
      if (newVal) {
        let date_now = moment(this.date_now).year()
        let date_born = moment(this.user.date_born).year()

        let age_year = parseInt(date_now - date_born)
        if (age_year >= 0 && age_year < 110) return (this.age = age_year)
        return (this.age = 'Se calcula por la F. Nac.')
      }
    },
  },
  created() {
    this.user = { ...this.data }
  },
  methods: {
    ...mapActions({
      createAction: 'user/create',
      updateAction: 'user/update',
    }),
    async formSubmit() {
      if (this.update) return await this.updateForm()
      return await this.createForm()
    },
    async createForm() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        await this.createAction(this.user).then(
          async (response) => {
            this.loading = false
            successNotify(
              {
                message: 'Creado con exito',
              },
              this.$swal
            )
            await this.$refs.closeModal.click()
            return await this.$parent.$parent.$parent.loadUsers()
          },
          (error) => {
            this.loading = false
            return errorRequest(error, this.$swal)
          }
        )
      }
      this.loading = false
    },
    async updateForm() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        await this.updateAction(this.user).then(
          async (response) => {
            this.loading = false
            successNotify(
              {
                message: 'Actualizado con exito',
              },
              this.$swal
            )
            await this.$refs.closeModal.click()
            return await this.$parent.$parent.$parent.loadUsers()
          },
          (error) => {
            this.loading = false
            return errorRequest(error, this.$swal)
          }
        )
      }
      this.loading = false
    },
  },
}
</script>
