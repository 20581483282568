<template>
  <modal :id="id" :size="modalSize">
    <template v-slot:header>
      <h2 class="h5 modal-title"
        ><span class="fa fa-user-tie" aria-hidden="true"></span> Editar
        Socio</h2
      >
      <a data-bs-dismiss="modal" aria-label="Close" @click="close">
        <span class="fa fa-times" aria-hidden="true"></span>
      </a>
    </template>
    <template v-slot:body>
      <form-create-update
        :id="id"
        :update="true"
        :close="close"
        :icon="'fa fa-save'"
        :btntext="'Actualizar'"
        :btnclass="'btn-custom2'"
        :data="user"
        v-if="Object.keys(user).length"
      />
    </template>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@components/app/Modal.vue'
import FormCreateUpdate from '@components/user/FormCreateUpdate.vue'

export default {
  name: 'UpdateRecord',
  props: ['id', 'close'],
  components: {
    Modal,
    FormCreateUpdate,
  },
  data: function () {
    return {
      modalSize: 'xl',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/userDataUpdated',
    }),
  },
}
</script>
