<template>
  <modal :id="id" :size="size">
    <template v-slot:header>
      <div class="container">
        <div class="row">
          <div class="col">
            <h2 class="h5 modal-title">
              <span class="fa fa-file-excel" aria-hidden="true"></span>
              Importación de Socios
            </h2>
          </div>
          <div class="col d-none d-sm-block">
            <div class="float-end">
              <button
                type="button"
                class="btn btn-info"
                @click="downloadExample"
              >
                <i class="fas fa-download" aria-hidden="true"></i> Descargar
                Ejemplo
              </button>
            </div>
          </div>
        </div>
      </div>
      <a data-bs-dismiss="modal" aria-label="Close" @click="close">
        <span class="fa fa-times" aria-hidden="true"></span>
      </a>
    </template>
    <template v-slot:body>
      <form-import :id="id" :close="close" />
    </template>
  </modal>
</template>

<script>
import Modal from '@components/app/Modal.vue'
import FormImport from '@components/user/FormImport.vue'
import { saveAs } from 'file-saver'

export default {
  name: 'ImportAction',
  props: ['id', 'close'],
  components: {
    Modal,
    FormImport,
  },
  data() {
    return {
      size: 'xl',
    }
  },
  methods: {
    downloadExample() {
      let timestamp = new Date().getTime()
      let backhost = process.env.VUE_APP_API_URL
      saveAs(
        `${backhost}static/excel/importacion.xlsx`,
        `importacion_${timestamp}.xlsx`
      )
    },
  },
}
</script>
