<template>
  <form :id="id" class="row g-3" @submit.prevent="formSubmit">
    <div class="col-md-6">
      <label class="form-label">Fecha de creación [Inicio]</label>
      <input type="date" class="form-control" v-model="date_start" />
    </div>
    <div class="col-md-6">
      <label class="form-label">Fecha de creación [Fin]</label>
      <input type="date" class="form-control" v-model="date_end" />
    </div>
    <div class="col-md-12">
      <label class="form-label">Socios</label>
      <multiselect
        v-model="users_select"
        :options="users.map((type) => type.id)"
        :custom-label="(opt) => users.find((x) => x.id == opt).username"
        placeholder="Seleccione"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :preselect-first="true"
      ></multiselect>
    </div>
    <div class="mt-4 modal-footer border-top border-5 border-light">
      <div v-show="!loading">
        <button
          type="button"
          class="btn btn-danger mr-2"
          data-bs-dismiss="modal"
          @click="close"
        >
          <span class="fa fa-times" aria-hidden="true"></span> Cancelar
        </button>
        <button type="submit" class="btn btn-info">
          <span class="fa fa-file-download" aria-hidden="true"></span> Descargar
        </button>
      </div>
      <div v-show="loading">
        <button class="btn btn-info disabled">
          <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
          Cargando
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { saveAs } from 'file-saver'
import { successRequest, errorRequest } from '@helpers'

export default {
  name: 'FormReport',
  props: ['id', 'close'],
  data() {
    return {
      loading: false,
      date_start: '',
      date_end: '',
      users_select: [],
      users: [],
    }
  },
  async mounted() {
    await this.selectAction().then(
      (response) => {
        this.users = response.data
      },
      (error) => {
        return errorRequest(error, this.$swal)
      }
    )
  },
  methods: {
    ...mapActions({
      reportAction: 'user/report',
      selectAction: 'user/selectOptions',
    }),
    async formSubmit() {
      this.loading = true
      let params = {}
      if (this.users_select) params.users = this.users_select
      if (this.date_start && this.date_end) {
        params.date_start = this.date_start
        params.date_end = this.date_end
      }
      await this.reportAction(params).then(
        async (response) => {
          this.loading = false
          let timestamp = new Date().getTime()
          let backhost = process.env.VUE_APP_API_URL
          saveAs(`${backhost}${response.path}`, `users_${timestamp}.xlsx`)
          return successRequest(
            {
              message: 'Reporte Descargado',
            },
            this.$swal
          )
        },
        (error) => {
          this.loading = false
          return errorRequest(error, this.$swal)
        }
      )
      this.loading = false
    },
  },
}
</script>
