<template>
  <modal :id="id" :size="modalSize" :title="false">
    <template v-slot:body>
      <form class="row g-3" @submit.prevent="formSubmit">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-auto">
              <div class="text-center mb-1">
                <span
                  class="fa fa-envelope fa-10x mb-3 text-custom2"
                  aria-hidden="true"
                ></span>
              </div>
              <div class="text-center mb-3">
                <span class="text-break fw-bolder lh-sm text-custom2">
                  ¿ESTAS SEGURO(A) DE ENVIAR UN CORREO CON SUS CREDENCIALES AL
                  SOCIO?
                </span>
              </div>
            </div>
            <div class="col-md-auto">
              <div v-show="!loading">
                <div class="d-grid gap-2 d-md-block">
                  <button type="submit" class="btn btn-success me-md-2">
                    <span class="fa fa-check" aria-hidden="true"></span> Si
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                    @click="close"
                    ref="closeModal"
                  >
                    <span class="fa fa-times" aria-hidden="true"></span> No
                  </button>
                </div>
              </div>
              <div v-show="loading">
                <div class="d-grid gap-1 d-md-block">
                  <button class="btn btn-success disabled">
                    <span
                      class="fa fa-spinner fa-spin"
                      aria-hidden="true"
                    ></span>
                    Cargando
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Modal from '@components/app/Modal.vue'
import { errorRequest, successNotify } from '@helpers'

export default {
  name: 'EmailAction',
  props: ['id', 'close'],
  components: {
    Modal,
  },
  data: function () {
    return {
      modalSize: 'sm',
      user: {},
      loading: false,
    }
  },
  computed: {
    ...mapState({
      user_data: (state) => state.user.dataUpdate,
    }),
  },
  watch: {
    user_data: function (newVal, oldVal) {
      this.user = newVal
    },
  },
  methods: {
    ...mapActions({
      emailAction: 'user/sendEmailWelcome',
    }),
    async formSubmit() {
      this.loading = true
      await this.emailAction({ id: this.user.id }).then(
        async (response) => {
          this.loading = false
          successNotify(
            {
              message: 'Se envio el correo con sus credenciales con exito',
            },
            this.$swal
          )
          await this.$refs.closeModal.click()
          return true
        },
        (error) => {
          this.loading = false
          return errorRequest(error, this.$swal)
        }
      )
      this.loading = false
    },
  },
}
</script>
