<template>
  <modal :id="id" :size="modalSize" :title="false">
    <template v-slot:body>
      <form-status-delete
        :id="id"
        :close="close"
        :status="false"
        :data="user"
        v-if="Object.keys(user).length"
      />
    </template>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@components/app/Modal.vue'
import FormStatusDelete from '@components/user/FormStatusDelete.vue'

export default {
  name: 'DeleteRecord',
  props: ['id', 'close'],
  components: {
    Modal,
    FormStatusDelete,
  },
  data: function () {
    return {
      modalSize: 'sm',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/userDataUpdated',
    }),
  },
}
</script>
