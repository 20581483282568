<template>
  <modal :id="id" :size="size">
    <template v-slot:header>
      <h2 class="h5 modal-title">
        <span class="fa fa-file-download" aria-hidden="true"></span> Filtros de
        Reporte de Socios
      </h2>
      <a data-bs-dismiss="modal" aria-label="Close" @click="close">
        <span class="fa fa-times" aria-hidden="true"></span>
      </a>
    </template>
    <template v-slot:body>
      <form-record :id="id" :close="close" />
    </template>
  </modal>
</template>

<script>
import Modal from '@components/app/Modal.vue'
import FormRecord from '@components/user/FormReport.vue'

export default {
  name: 'ReportAction',
  props: ['id', 'close'],
  components: {
    Modal,
    FormRecord,
  },
  data() {
    return {
      size: 'md',
    }
  },
}
</script>
