<template>
  <form :id="id" class="row g-3" @submit.prevent="formSubmit">
    <div class="col-md-4">
      <div class="card card-shadow text-center">
        <div class="card-block">
          <h1 class="icon-import" v-html="fileIcon"></h1>
          <div
            class="mt-20"
            :class="formInitialize ? 'disabled' : ''"
            :disabled="formInitialize"
          >
            <button type="button" class="btn btn-primary btn-upload">
              <i class="fas fa-upload" aria-hidden="true"></i> Upload
              <input
                type="file"
                :name="uploadFieldName"
                @change="onFileChange($event.target.name, $event.target.files)"
              />
            </button>
          </div>
        </div>
        <div class="card-footer">
          <div class="row no-space">
            <div class="col-6">
              <strong class="profile-stat-count" v-html="fileFormat.type"
                >-</strong
              >
              <span> Tipo</span>
            </div>
            <div class="col-6">
              <strong class="profile-stat-count" v-html="fileSize"></strong>
              <span> Peso</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card card-shadow example-responsive" id="widgetLinearea">
        <div class="card-block">
          <ul class="list-group list-group-gap mt-20">
            <li class="list-group-item bg-blue-grey-100">
              <i class="icon wb-tag" aria-hidden="true"></i> Nombre :
              <span v-html="fileData.name"></span>
            </li>
            <li class="list-group-item bg-blue-grey-100">
              <i class="icon wb-time" aria-hidden="true"></i> Ultima
              Modificación :
              <span v-html="dateLastModified"></span>
            </li>
            <li class="list-group-item bg-blue-grey-100">
              <i class="icon wb-bell" aria-hidden="true"></i> Tiempo de subida :
              <span v-html="timeUpload"></span>
            </li>
          </ul>
          <template v-if="alertType.text">
            <div
              class="alert mt-2"
              :class="alertType.class"
              role="alert"
              v-html="alertType.text"
            ></div>
          </template>
        </div>
      </div>
    </div>
    <div class="mt-4 modal-footer border-top border-5 border-light">
      <div v-show="!loading">
        <button
          type="button"
          class="btn btn-danger mr-2"
          data-bs-dismiss="modal"
          @click="close"
        >
          <span class="fa fa-times" aria-hidden="true"></span> Cancelar
        </button>
        <button type="submit" class="btn btn-info" :disabled="buttonUpload">
          <span class="fa fa-file-upload" aria-hidden="true"></span> Importar
        </button>
      </div>
      <div v-show="loading">
        <button class="btn btn-info disabled">
          <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
          Importando
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import {
  successRequest,
  errorRequest,
  chronometerSeconds,
  fileBytes,
  fileFormat,
} from '@helpers'
import moment from 'moment'

export default {
  name: 'FormImport',
  props: ['id', 'close'],
  data() {
    return {
      uploadFieldName: 'file',
      fileFormat: {
        icon: 'fas fa-file',
        type: '-',
      },
      fileData: {
        name: '-',
        lastModified: '-',
      },
      fileIcon: '<span class="fas fa-file" />',
      fileSize: '0 kb',
      fileFormData: '',
      timeUpload: '00:00:00',
      formatAccepted: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
      alertType: {},
      buttonUpload: true,
      formInitialize: false,
      loading: false,
    }
  },
  computed: {
    dateLastModified() {
      if (this.fileData.name !== '-') {
        let fileData = this.fileData
        if (
          fileData.lastModified === '-' ||
          fileData.lastModified === undefined
        )
          return '-'
        return moment(fileData.lastModified).format('DD/MM/YYYY hh:mm a')
      }

      return '-'
    },
  },
  watch: {
    fileFormData() {
      let fileFormData = this.fileFormData
      const { formatAccepted } = this
      this.fileData = fileFormData
      if (!formatAccepted.includes(fileFormData.type)) {
        this.alertType.class = 'alert-danger'
        this.alertType.text =
          'El formato del archivo es incorrecto para este modulo, recuerda que debe ser un Excel'
        this.buttonUpload = true
        return true
      }

      this.alertType.class = 'alert-success'
      this.alertType.text = 'El formato del archivo es correcto'
      this.buttonUpload = false
      return true
    },
    'fileFormat.icon'(newValue, oldValue) {
      this.fileIcon = `<span class='${newValue}' />`
    },
  },
  async mounted() {},
  methods: {
    ...mapActions({
      importAction: 'user/import',
    }),
    onFileChange: function (fieldName, file) {
      this.timeUpload = '00:00:00'
      let dataFile = file[0]
      if (file.length > 0) {
        this.fileSize = fileBytes(dataFile.size)
        this.fileFormat = fileFormat(dataFile.type)
        this.fileFormData = dataFile
      }
    },
    initChronometer: function () {
      let dateNow = new Date().getTime()
      this.interval = setInterval(
        async function () {
          let dateElapsed = new Date().getTime()
          let elapsed = chronometerSeconds(dateElapsed - dateNow)
          if (elapsed !== 'NaN:NaN:NaN') this.timeUpload = elapsed
        }.bind(this),
        1000
      )
    },
    clearChronometer: function () {
      return clearInterval(this.interval)
    },
    async formSubmit() {
      this.loading = true
      let bodyFormData = new FormData()
      if (this.fileFormData) bodyFormData.append('file', this.fileFormData)
      this.initChronometer()
      await this.importAction(bodyFormData).then(
        async (response) => {
          this.loading = false
          this.clearChronometer()
          await this.$parent.$parent.$parent.loadUsers()
          return successRequest(
            {
              message: 'Se importa la data con exito',
            },
            this.$swal
          )
        },
        (error) => {
          this.clearChronometer()
          this.loading = false
          return errorRequest(error, this.$swal)
        }
      )
    },
  },
}
</script>
